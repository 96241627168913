import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '@core';
import { ImageCore } from '@components/image';
import percentSVG from '@images/deals/deals-header/percent.svg';

const pulseAnimation = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
`;

const fadeIn = keyframes`
  0% { 
    opacity: 0; 
  }
  100% {
     opacity: 1; 
  }
`;

const Image = styled(ImageCore)`
  object-fit: cover;
`;

const AnimatedImage = styled(Image)`
  animation: ${pulseAnimation} 2s ease-in-out 1s infinite both, ${fadeIn} 1s ease-out;
`;

const BFAnimatedImage = ({ image }) => {
  const [isHeaderImgLoaded, setIsHeaderImgLoaded] = useState(false);

  const headerImgRef = useRef();

  // workaround here to display animated image only when the main header asset is loaded
  useEffect(() => {
    if (headerImgRef.current?.complete && !isHeaderImgLoaded) {
      setIsHeaderImgLoaded(true);
    }
  }, [isHeaderImgLoaded]);

  return (
    <Box position={{ _: 'relative', lg: 'absolute' }} zIndex={-1}>
      <Box position="relative" {...image?.position}>
        <Image
          alt={image?.alt}
          ref={headerImgRef}
          onLoad={() => setIsHeaderImgLoaded(true)}
          src={image?.src}
        />
        {isHeaderImgLoaded && (
          <AnimatedImage
            src={percentSVG}
            alt="discount"
            bottom={{ _: 100, sm: 214, md: 278, lg: 280, xl: 330 }}
            left={{ _: 280, sm: 309, md: 407, lg: 186, xl: 220 }}
            height={{ _: 39, md: 60, lg: 39, xl: 51 }}
            position="absolute"
          />
        )}
      </Box>
    </Box>
  );
};

export default BFAnimatedImage;
