import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Box } from '@core';
import { Animation, ImageCore } from '@components';
import { useIsClientSide } from '@hooks';

import { DiscountSticker } from './discount-sticker';

const CTAImage = styled(ImageCore)`
  object-fit: cover;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const animation = css`
  animation: ${pulse} 3s infinite;
`;

const AnimatedWrapper = styled(Box)`
  ${animation}
`;

const AnimatedBFImage = ({ image }) => {
  const { isClientSide } = useIsClientSide();

  return (
    <Box
      {...image?.position}
      position="absolute"
      top={{ lg: 70, xl: -30 }}
      left={{ lg: '27%', xl: '33%' }}
    >
      <Box position="relative" height="100%">
        <CTAImage
          id="header-img"
          src={image?.src}
          alt={image?.alt}
          position="absolute !important" // !important is for gatsby image to work properly in build
          zIndex={-1}
          left="50%"
          {...image?.position}
        />
        {isClientSide && (
          <Animation.FadeIn duration={250}>
            <AnimatedWrapper
              position="absolute"
              right={{ lg: '-23%', xl: '-25%' }}
              top={{ lg: '39%', xl: '42%' }}
              width={{ lg: 170, xl: 190 }}
            >
              <DiscountSticker />
            </AnimatedWrapper>
          </Animation.FadeIn>
        )}
      </Box>
    </Box>
  );
};

export default AnimatedBFImage;
